import { useState } from "react";
import Auth from "./Auth";
import { isAdmin } from "../utils/utils";
import {Link} from 'react-router-dom';
// import logo from '../logo.png'

function Navbar({showModal, setShowModal}){

    const [isUserLoggedIn, setIsUserLoggedIn] = useState(localStorage.getItem("userData") == undefined ? false: true)

    const [showMobileMenuData, setShowMobileMenuData] = useState({show: false, showClass:'show', hideClass:'collapse'})

    const handleMobileMenu= () =>{
        setShowMobileMenuData({...showMobileMenuData, show: !showMobileMenuData.show})
    }
    
    const handleLogout = () =>{
        let ipData = localStorage.getItem("ipData")
        let sessioData = localStorage.getItem("session_id")
        localStorage.clear()
        if(ipData != undefined){
            localStorage.setItem('ipData',ipData)
        }
        if(sessioData != undefined){
            localStorage.setItem('session_id',sessioData)
        }
        window.location = '/'
    }

    const [toggler, setToggler] = useState({login: true, signup: false, reset: false})

    const handleAuthClicks = (modalName) =>{
        setShowModal(true)
        if(modalName == 'login'){
            setToggler({login: true, signup: false, reset: false})
        }
        if(modalName == 'signup'){
            setToggler({login: false, signup: true, reset: false})
        }
    }

    

    return(
        <div>
            <header id="header" className="navbar navbar-expand-lg navbar-floating navbar-end navbar-dark">
                <div className="container">
                    <nav className="js-mega-menu navbar-nav-wrap navbar-floating-nav bg-ss-dark hs-menu-initialized hs-menu-horizontal">
                        
                        <a className="navbar-brand m-ss-1" href="/" aria-label="SoftwareSchool">
                            <img className="navbar-brand-logo" src="https://d2b98ifobtd07j.cloudfront.net/logo2.png" alt="SoftwareSchool" />
                        </a>
                        <button className="navbar-toggler" type="button" onClick={e=>handleMobileMenu()} data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
                            <span className="navbar-toggler-default">
                                <i className="bi-list"></i>
                            </span>
                            <span className="navbar-toggler-toggled">
                                <i className="bi-x"></i>
                            </span>
                        </button>
                        <div className={showMobileMenuData.show ? showMobileMenuData.showClass + ' navbar-collapse bg-ss-dark': showMobileMenuData.hideClass + ' navbar-collapse bg-ss-dark'} id="navbarNavDropdown">
                            <ul className="navbar-nav bg-ss-dark">
                                <li className="hs-has-mega-menu nav-item">
                                    <Link className=" nav-link ss-bold ss-link" to="/">Home</Link>
                                </li>
                                <li className="hs-has-mega-menu nav-item">
                                    <Link className=" nav-link ss-bold ss-link" to="courses">Courses</Link>
                                </li>
                                {
                                    isUserLoggedIn == true && 
                                    <li className="hs-has-mega-menu nav-item">
                                        <Link className=" nav-link ss-bold ss-link" to="my-courses">My Courses</Link>
                                    </li>
                                } 
                                {
                                    isAdmin() && 
                                    <li className="hs-has-mega-menu nav-item">
                                        <a className=" nav-link  ss-bold ss-link" href="admin-console">Admin Console</a>
                                    </li>
                                }
                                {
                                    isUserLoggedIn == true && 
                                    <li className="hs-has-mega-menu nav-item">
                                        <a className=" nav-link ss-bold ss-link" href='profile'>Profile</a>
                                    </li>
                                }
                                {
                                    isUserLoggedIn == true && 
                                    <li className="hs-has-mega-menu nav-item">
                                        <button className="btn ss-link-btn btn-soft-light " onClick={e=>handleLogout()} >Logout</button>
                                    </li>
                                }
                                {
                                    isUserLoggedIn == false && <li className="nav-item">
                                        <button className="btn ss-link-btn btn-soft-light " onClick={e => handleAuthClicks('login')} >Login</button>
                                    </li>
                                }
                                {
                                    isUserLoggedIn == false && <li className="nav-item">
                                        <button className="btn ss-link-btn btn-soft-light " onClick={e => handleAuthClicks('signup') } >Signup</button>
                                    </li>
                                }
                                <li className="nav-item">
                                    <a className="btn ss-link-btn-color btn-warning " target="_blank"  id="callUs" href="https://wa.me/919032029072?text=Hi" >WhatsApp/Call</a>
                                </li>
                                
                            </ul>
                        </div>
                    </nav>
                </div>
                {
                    showModal && <Auth hideModal={setShowModal} togglerData={toggler} />
                }
            </header>
            
        </div>
    )
}

export default Navbar;